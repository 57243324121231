<template>
  <v-dialog
    v-model="dialog"
    max-width="300px"
    :persistent="load"
  >
    <v-card :disabled="load">
      <v-card-title>
        {{ p_nuevo ? 'Nuevo rubro' : 'Editar rubro' }}
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="form">
          <v-row class="pt-8">
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="rubro.nombre"
                label="Nombre"
                :rules="[rules.required]"
                validate-on-blur
                autofocus
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-autocomplete
                v-model="rubro.familia_codigo"
                label="Familia"
                item-text="nombre"
                item-value="codigo"
                :rules="[rules.required]"
                :items="p_familias"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-text-field
                v-model="rubro.ind_rot_min"
                label="Índice min"
                type="number"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-text-field
                v-model="rubro.ind_rot_max"
                label="Índice max"
                type="number"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="rubro.cant_art_desc_empleado"
                label="Cant. límite anual Desc. Empleados"
                type="number"
                outlined
                dense
                :disabled="permiso_editar == 0"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="error"
          class="mr-2"
          :disabled="load"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <BtnConfirmar
          :loading="load"
          @action="guardar()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 *  Modal para editar o crear un rubro
 * 
 *  Notas: este componente emite acciones (@nuevo, @editar) al padre caundo se crea o edita un rubro (en caso de estar definidas)
 *         todos los props son obligatorios
 */
import BtnConfirmar from '../util/BtnConfirmar'

export default {
  data () {
    return {
      load: false,
      rubro: {},
      rules: {
        required: value => !!value || 'Campo requerido',
      },
      permiso_editar: 0
    }
  },
  props: {
    value: Boolean,
    p_nuevo: Boolean, // para editar un rubro enviar en false, para un rubro nuevo en true
    p_rubro: Object,  // en caso de ser nuevo enviar el objeto vacio
    p_familias: Array // array de familias con codigo y nombre
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    BtnConfirmar
  },
  watch: {
    dialog (val) {
      if (val) {
        if (this.p_nuevo) {
          // si es nuevo setea los campos en null
          this.rubro = {
            codigo: null,
            nombre: null,
            familia_codigo: null,
            ind_rot_min: null,
            ind_rot_max: null,
            cant_art_desc_empleado: null,
          }
        } else {
          // crea una copia del prop p_rubro para editarlo
          this.rubro = JSON.parse(JSON.stringify(this.p_rubro))
        }
        this.init();
      } else {
        this.$refs.form.resetValidation()
      }
    },
    'rubro.cant_art_desc_empleado': {
      handler:function(val){
        if(val < 0){
          this.rubro.cant_art_desc_empleado = 0;
        }
      },
      deep: true,
    }
  },
  methods: {
    async init(){
       // obtengo los permisos
      this.$store.state.loading = true
      let res1 = await this.$store.dispatch('genericosAcc/getPermiso',{ funcion_codigo: parseInt(167) });
      this.$store.state.loading = false
      if(res1.resultado == 0){
        return this.$store.dispatch('show_snackbar', { text: res1.message, color: 'error' })
      }
      this.permiso_editar = res1.permiso;
    },
    async guardar () {
      if (this.$refs.form.validate()) {
        this.load = true
        if (this.p_nuevo) {
          await this.$store.dispatch('rubros/nuevo_rubro', this.rubro)
            .then((res) => {
              this.dialog = false
              // emite la accion 'nuevo' definida en el padre
              this.$emit('nuevo', res.rubro)
              // muestra mensaje
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'success',
              })
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error',
              })
            })
        } else {
          // solo edita si realmente cambio el nombre, la familia o los indices
          if (JSON.stringify(this.rubro) != JSON.stringify(this.p_rubro)) {
            await this.$store.dispatch('rubros/editar_rubro', this.rubro)
              .then((res) => {
                this.dialog = false
                // hace un UPPER del nombre del rubro y obtiene el nombre de la familia
                this.rubro.nombre = this.rubro.nombre.toUpperCase()
                const familia = this.p_familias.find(familia => familia.codigo == this.rubro.familia_codigo)
                if (familia) {
                  this.rubro.familia_nombre = familia.nombre
                }
                // emite la accion 'editar' definida en el padre
                this.$emit('editar', this.rubro)
                // muestra mensaje
                this.$store.dispatch('show_snackbar', {
                  text: res.message,
                  color: 'success',
                })
              })
              .catch(error => {
                this.$store.dispatch('show_snackbar', {
                  text: error.message,
                  color: 'error',
                })
              })
          } else {
            this.dialog = false
            this.$store.dispatch('show_snackbar', {
              text: 'No se realizaron cambios en el rubro: el nombre, la familia y los índices son iguales a los anteriores',
              color: 'warning',
              timeout: 4000
            })
          }
        }
        this.load = false
      }
    }
  }
}
</script>