<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-btn
            style="position: absolute; top: -15px; right: 55px; z-index: 1"
            color="success"
            small
            fab
            @click="open_dialog(null)"
          >
            <v-icon>fas fa-plus</v-icon>
          </v-btn>
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar()">
              <v-row :no-gutters="$vuetify.breakpoint.xs">
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Familia
                  <v-autocomplete
                    v-model="filtro.familia"
                    item-text="nombre"
                    item-value="codigo"
                    tabindex="1"
                    :items="familias"
                    hide-details
                    clearable
                    autofocus
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Nombre
                  <v-text-field
                    v-model.trim="filtro.nombre"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="2" class="d-flex justify-center align-center py-1">
                  <v-switch
                    v-model="filtro.inhabilitado"
                    label="Inhabilitado"
                    class="mb-md-2"
                    tabindex="1"
                    :true-value="1"
                    :false-value="0"
                    hide-details
                    dense
                  ></v-switch>
                </v-col>
                <BtnFiltro
                  :loading="load"
                  @clear="limpiar()"
                />
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      
      <v-data-table
        class="cebra elevation-2 mt-2"
        :headers="headers"
        :items="rubros"
        :loading="load"
        :search="search"
        sort-by="nombre"
        dense
      >
        <template v-slot:top>
          <v-row class="d-flex justify-end pa-2" no-gutters>
            <v-col cols="6" sm="3" md="2">
              <v-text-field
                v-model="search"
                label="Buscar"
                hide-details
                single-line
                outlined
                dense
              >
                <template v-slot:append>
                  <v-icon
                    class="mt-1"
                    title="Buscar en los datos filtrados"
                    small
                  >
                    fas fa-search
                  </v-icon>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.inhabilitado`]="{ item }">
          <span
            v-text="item.inhabilitado ? 'SI' : 'NO'"
          ></span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            v-if="!item.inhabilitado"
            color="primary"
            title="Editar"
            class="mr-2"
            small
            @click="open_dialog(item)"
          >
            fas fa-pen
          </v-icon>
          <BtnConfirmar
            :icono="item.inhabilitado ? 'fas fa-check' : 'fas fa-ban'"
            :color="item.inhabilitado ? 'success' : 'error'"
            :title="item.inhabilitado ? 'Habilitar' : 'Inhabilitar'"
            :texto="item.inhabilitado
              ? `¿Desea habilitar el rubro <strong>${item.nombre}</strong> de la familia <strong>${item.familia_nombre}</strong>?`
              : `¿Desea inhabilitar el rubro <strong>${item.nombre}</strong> de la familia <strong>${item.familia_nombre}</strong>?`"
            :icon_button="true"
            :small="true"
            @action="item.inhabilitado ? habilitar(item) : inhabilitar(item)"
          />
        </template>
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table>
    </v-col>
    <Rubro
      v-model="dialog"
      :p_nuevo="nuevo"
      :p_rubro="rubro"
      :p_familias="familias"
      @editar="editar"
    />
  </v-row>
</template>

<script>
import BtnFiltro from '../../components/util/BtnFiltro'
import BtnConfirmar from '../../components/util/BtnConfirmar'
import Rubro from '../../components/generales/Rubro'
import { order_list_by } from '../../util/utils'

export default {
  data () {
    return {
      panel: 0,
      search: '',
      load: false,
      nuevo: false,
      dialog: false,
      familias: [],
      rubros: [],
      headers: [
        { text: 'Código', value: 'codigo', align: 'end' },
        { text: 'Nombre', value: 'nombre' },
        { text: 'Índice rotación min', value: 'ind_rot_min', align: 'end', sortable: false, filterable: false },
        { text: 'Índice rotación max', value: 'ind_rot_max', align: 'end', sortable: false, filterable: false },
        { text: 'Inhabilitado', value: 'inhabilitado', sortable: false, filterable: false },
        { text: 'Familia', value: 'familia_nombre' },
        { text: 'Acciones', value: 'actions', sortable: false, filterable: false },
      ],
      rubro: {},
      filtro: {
        familia: null,
        nombre: '',
        inhabilitado: 0
      }
    }
  },
  async created () {
    this.$store.state.loading = true
    this.familias = []
    await this.$store.dispatch('rubros/get_familias')
      .then((res) => {
        this.familias = res.data
      })
      .catch(error => {
        this.$store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error',
        })
      })
    // ordena las familias por nombre desc
    order_list_by(this.familias, 'nombre')
    this.$store.state.loading = false
  },
  components: {
    BtnFiltro,
    BtnConfirmar,
    Rubro
  },
  methods: {
    async habilitar (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('rubros/habilitar_rubro', item.codigo)
        .then((res) => {
          // actualiza el rubro en la lista
          item.inhabilitado = 0
          // muestra mensaje
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success',
          })
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      this.$store.state.loading = false
    },
    async inhabilitar (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('rubros/inhabilitar_rubro', item.codigo)
        .then((res) => {
          // actualiza el rubro en la lista
          item.inhabilitado = 1
          // muestra mensaje
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success',
          })
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      this.$store.state.loading = false
    },
    async buscar () {
      this.load = true
      this.search = ''
      this.rubros = []
      await this.$store.dispatch('rubros/get_rubros', this.filtro)
        .then((res) => {
          this.rubros = res.data
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      this.load = false
    },
    editar (item) {
      // actualiza el rubro en la lista
      let rubro = this.rubros.find(rubro => rubro.codigo == item.codigo)
      Object.assign(rubro, item)
    },
    open_dialog (item) {
      if (item) {
        this.nuevo = false
        this.rubro = JSON.parse(JSON.stringify(item))
      } else {
        this.nuevo = true
        this.rubro = {}
      }
      this.dialog = true
    },
    limpiar () {
      this.filtro = {
        familia: null,
        nombre: '',
        inhabilitado: 0
      }
    }
  }
}
</script>